import { checkLoginForShowSidebar } from "@/utils/functions";
export default {
  state: {
    userData: null,
    currentTab: null,
  },
  getters: {
    userData: (state) => state.userData,
    currentTab: (state) => state.currentTab,
  },
  mutations: {
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
      checkLoginForShowSidebar();
    },
    UPDATE_HISTORICAL_CURRENT_TAB: (state, currentTab) => {
      state.currentTab = currentTab;
    },
  },
  actions: {
    loginUser(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    updateUserData(context, userData) {
      context.commit("UPDATE_USER_DATA", userData);
    },
    updateCurrentTab(context, currentTab) {
      context.commit("UPDATE_HISTORICAL_CURRENT_TAB", currentTab);
    },
  },
  modules: {},
};
