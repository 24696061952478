import { ifNotAuthenticated } from "@/utils/functions";

import ServicesReservationAddedServices from "@/views/servicesReservationAddedServices/ServicesReservationAddedServices.vue";
import ServicesReservationAddedServiceAdd from "@/views/servicesReservationAddedServices/ServicesReservationAddedServiceAdd.vue";

const Routes = [
  {
    path: "/services-reservation-added-services",
    name: "ServicesReservationAddedServices",
    component: ServicesReservationAddedServices,
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
  },
  {
    path: "/add-services-reservation-added-service",
    name: "ServicesReservationAddedServiceAdd",
    component: ServicesReservationAddedServiceAdd,
    beforeEnter: ifNotAuthenticated,
    props(route) {
      return route.params || {};
    },
  },
];

export default Routes;
