import { ifNotAuthenticated } from "@/utils/functions";

import Employees from "@/views/employees/employees/Employees.vue";
import EmployeeAdd from "@/views/employees/employees/EmployeeAdd.vue";
import EmployeeEdit from "@/views/employees/employees/EmployeeEdit.vue";

const Routes = [
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/add-employee",
    name: "EmployeeAdd",
    component: EmployeeAdd,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/edit-employee/:employeeToken",
    name: "EmployeeEdit",
    component: EmployeeEdit,
    beforeEnter: ifNotAuthenticated,
  },
];

export default Routes;
