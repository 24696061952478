<template>
  <nav>
    <PreLoader v-if="isLoading" />
    <input type="checkbox" id="show-menu" @click="changeMenuStatus" />
    <label for="show-menu" class="menu-icon"><i class="fa fa-bars"></i></label>
    <!-- <span class="logo-icon" :title="$t('projectName')">
      <img src="@/assets/images/logo.png" class="logo" />
    </span> -->
    <div class="page-name">
      <!-- <img src="@/assets/images/homepage.svg" /> -->
      <img :src="require('@/assets/images/' + pageIcon)" />
      <h3>{{ pageName }}</h3>
    </div>
    <div class="notifications-icon">
      <b-dropdown variant="link" no-caret @show="openAllNotifications()">
        <template #button-content>
          <img class="icon" src="@/assets/images/notifications.svg" />
          <b-badge
            v-if="notificationsCount != 0"
            variant="danger"
            class="count-notifications"
            >{{ notificationsCount }}</b-badge
          >
        </template>

        <!-- <b-dropdown-item
          v-for="(notification, index) in notificationsData"
          :key="index"
          :class="{ 'read-done': notification.statusRead == true }"
          @click="readNotification(notification)"
        >
          <h6 class="notifications-title">
            {{ isDataExist(notification.titleCurrent) }}
          </h6>
          <p class="notifications-description cut-1line">
            {{ isDataExist(notification.bodyCurrent) }}
          </p>
        </b-dropdown-item> -->

        <div v-if="notificationsData" class="dropdown-list">
          <div
            v-for="(notification, index) in notificationsData"
            :key="index"
            class="list-item"
            :class="{ 'read-done': notification.statusRead == true }"
          >
            <div
              class="list-item-content"
              @click="readNotification(notification)"
            >
              <h5 class="list-item-title cut-2line">
                <span
                  class="status-circle"
                  :class="{
                    'status-red': notification.statusRead != true,
                    'status-grey': notification.statusRead == true,
                  }"
                ></span>
                {{ isDataExist(notification.titleCurrent) }}
              </h5>
              <p class="list-item-description cut-2line">
                {{ isDataExist(notification.bodyCurrent) }}
              </p>
              <span class="list-item-date">
                {{ isDataExist(notification.dateTimeAgo) }}
              </span>
            </div>
          </div>
        </div>
        <b-dropdown-item v-else>
          <h6 class="notifications-title">{{ exceptionMsg }}</h6>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item to="/notifications">
          <h6>{{ $t("notifications.showAll") }}</h6>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="user-icon" v-if="userData">
      <b-dropdown id="dropdown-user">
        <template #button-content>
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
          />
        </template>
        <div class="text-center">
          <img
            :src="employeeImage"
            :onerror="`this.src='${defaultImg}'`"
            alt="images"
            class="img-profile"
          />
          <b-dropdown-item>{{ employeeName }}</b-dropdown-item>
        </div>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-if="language == 'en'" @click="changeLanguage('ar')">
          <img src="@/assets/images/lang-ar.svg" />
          العربية
        </b-dropdown-item>
        <b-dropdown-item v-if="language == 'ar'" @click="changeLanguage('en')">
          <img src="@/assets/images/lang-en.svg" />
          English
        </b-dropdown-item>
        <!-- <b-dropdown-item v-if="theme == 'dark'" @click="changeTheme('light')">
          <img src="@/assets/images/mode-light.svg" />
          {{ $t("lightMode") }}
        </b-dropdown-item>
        <b-dropdown-item v-if="theme == 'light'" @click="changeTheme('dark')">
          <img src="@/assets/images/mode-dark.svg" />
          {{ $t("darkMode") }}
        </b-dropdown-item> -->
        <b-dropdown-item v-b-modal.UserChangePassword>
          <img src="@/assets/images/changePassword.svg" />
          {{ $t("changePassword") }}
        </b-dropdown-item>
        <b-dropdown-item v-b-modal.UserLogOut>
          <img src="@/assets/images/logout.svg" />
          {{ $t("logout") }}
        </b-dropdown-item>
        <!-- <b-dropdown-item active>Active action</b-dropdown-item> -->
      </b-dropdown>
    </div>

    <UserChangePassword v-on:logout="logout" />
    <UserLogOut v-on:logout="logout" />
  </nav>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import { BASE_URL, STATUS } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/user.jpg";
import { isDataExist } from "@/utils/functions";
import { mapGetters } from "vuex";
import UserChangePassword from "@/components/user/UserChangePassword.vue";
import UserLogOut from "@/components/user/UserLogOut.vue";
import Notification from "@/models/notifications/Notification";

export default {
  name: "Navbar",
  components: {
    PreLoader,
    UserChangePassword,
    UserLogOut,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      theme: localStorage.getItem("userTheme") || "light",
      checked: false,
      pageName: this.$t("homePage"),
      pageIcon: "homepage.svg",
      defaultImg: DEFAULT_IMG,
      notificationData: new Notification(),
      notificationsData: null,
      notificationsErrorMsg: null,
      notificationsCount: 0,
    };
  },
  methods: {
    logout() {
      this.isLoading = true;
      this.$store.dispatch("updateUserData", null);
      this.$router.push("/").catch(() => {});
    },
    changeLanguage(lang) {
      this.isLoading = true;
      localStorage.setItem("userLanguage", lang);
      window.location.reload();
    },
    changeTheme(theme) {
      this.isLoading = true;
      localStorage.setItem("userTheme", theme);
      window.location.reload();
    },
    changeMenuStatus() {
      this.$emit("changeMenuStatus");
    },
    getCurrentPage(path) {
      if (path == "/") {
        this.pageName = this.$t("homePage");
        this.pageIcon = "homepage.svg";
      } else if (path.indexOf("/body-anatomy-types") == 0) {
        this.pageName = this.$t("bodyAnatomyTypes.modelName");
        this.pageIcon = "anatomy-types.svg";
      } else if (path.indexOf("/add-body-anatomy-type") == 0) {
        this.pageName = this.$t("bodyAnatomyTypes.add");
        this.pageIcon = "anatomy-types.svg";
      } else if (path.indexOf("/edit-body-anatomy-type") == 0) {
        this.pageName = this.$t("bodyAnatomyTypes.edit");
        this.pageIcon = "anatomy-types.svg";
      } else if (path.indexOf("/body-anatomies") == 0) {
        this.pageName = this.$t("bodyAnatomies.modelName");
        this.pageIcon = "anatomy.svg";
      } else if (path.indexOf("/add-body-anatomy") == 0) {
        this.pageName = this.$t("bodyAnatomies.add");
        this.pageIcon = "anatomy.svg";
      } else if (path.indexOf("/edit-body-anatomy") == 0) {
        this.pageName = this.$t("bodyAnatomies.edit");
        this.pageIcon = "anatomy.svg";
      } else if (path.indexOf("/measurement-rooms") == 0) {
        this.pageName = this.$t("measurementRooms.name");
        this.pageIcon = "measurement-rooms.svg";
      } else if (path.indexOf("/add-measurement-room") == 0) {
        this.pageName = this.$t("measurementRooms.add");
        this.pageIcon = "measurement-rooms.svg";
      } else if (path.indexOf("/edit-measurement-room") == 0) {
        this.pageName = this.$t("measurementRooms.edit");
        this.pageIcon = "measurement-rooms.svg";
      } else if (path.indexOf("/reservations-medias") == 0) {
        this.pageName = this.$t("reservationsMedias.modelName");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/add-reservation-media") == 0) {
        this.pageName = this.$t("reservationsMedias.add");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/edit-reservation-media") == 0) {
        this.pageName = this.$t("reservationsMedias.edit");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/employee-slices") == 0) {
        this.pageName = this.$t("slices");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/add-employee-slice") == 0) {
        this.pageName = this.$t("addSlice");
        this.pageIcon = "slices.svg";
      } else if (path.indexOf("/employee-salary-settings") == 0) {
        this.pageName = this.$t("EmployeeSalarySetting");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/add-employee-salary-setting") == 0) {
        this.pageName = this.$t("EmployeeSalarySettingAdd");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/edit-employee-salary-setting") == 0) {
        this.pageName = this.$t("EmployeeSalarySettingEdit");
        this.pageIcon = "employee-salary-setting.svg";
      } else if (path.indexOf("/employee-work-schedules") == 0) {
        this.pageName = this.$t("EmployeeWorkSchedule");
        this.pageIcon = "work-schedule.svg";
      } else if (path.indexOf("/add-employee-work-schedule") == 0) {
        this.pageName = this.$t("EmployeeWorkScheduleAdd");
        this.pageIcon = "work-schedule.svg";
      } else if (path.indexOf("/employee-official-holidays-plans") == 0) {
        this.pageName = this.$t("EmployeeOfficialHolidaysPlans");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/add-employee-official-holidays-plan") == 0) {
        this.pageName = this.$t("EmployeeOfficialHolidaysPlanAdd");
        this.pageIcon = "holiday.svg";
      } else if (
        path.indexOf("/employees-input-sheet-attendance-merges") == 0
      ) {
        this.pageName = this.$t("employeesInputSheetAttendanceMerges");
        this.pageIcon = "merge.svg";
      } else if (
        path.indexOf("/add-employees-input-sheet-attendance-merge") == 0
      ) {
        this.pageName = this.$t("employeesInputSheetAttendanceMergeAdd");
        this.pageIcon = "merge.svg";
      } else if (path.indexOf("/employees-input-sheets-attendance") == 0) {
        this.pageName = this.$t("employeesInputSheetsAttendance");
        this.pageIcon = "sheets.svg";
      } else if (path.indexOf("/add-employees-input-sheet-attendance") == 0) {
        this.pageName = this.$t("employeesInputSheetAttendanceAdd");
        this.pageIcon = "sheets.svg";
      } else if (path.indexOf("/employees") == 0) {
        this.pageName = this.$t("theEmployees");
        this.pageIcon = "employees.svg";
      } else if (path.indexOf("/add-employee") == 0) {
        this.pageName = this.$t("employeeAdd");
        this.pageIcon = "addUser.svg";
      } else if (path.indexOf("/edit-employee") == 0) {
        this.pageName = this.$t("employeeEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/attendance-employee") == 0) {
        this.pageName = this.$t("attendanceHistory");
        this.pageIcon = "attendance.svg";
      } else if (path.indexOf("/add-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-attendance-employee") == 0) {
        this.pageName = this.$t("attendanceEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/movement-employee") == 0) {
        this.pageName = this.$t("employeeMovement");
        this.pageIcon = "address.svg";
      } else if (path.indexOf("/add-movement-employee") == 0) {
        this.pageName = this.$t("movementAdd");
        this.pageIcon = "plus.svg";
      } else if (path.indexOf("/edit-movement-employee") == 0) {
        this.pageName = this.$t("movementEdit");
        this.pageIcon = "pencil.svg";
      } else if (path.indexOf("/employee-movement-media") == 0) {
        this.pageName = this.$t("media");
        this.pageIcon = "media.svg";
      } else if (path.indexOf("/attendance-history") == 0) {
        this.pageName = this.$t("attendanceAndDepartureHistory");
        this.pageIcon = "attendanceHistory.svg";
      } else if (path.indexOf("/add-attendance") == 0) {
        this.pageName = this.$t("checkIn");
        this.pageIcon = "add-attendance.svg";
      } else if (path.indexOf("/add-departure") == 0) {
        this.pageName = this.$t("checkOut");
        this.pageIcon = "add-departure.svg";
      } else if (path.indexOf("/points") == 0) {
        this.pageName = this.$t("points");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/employee-points") == 0) {
        this.pageName = this.$t("employeePoints");
        this.pageIcon = "points.svg";
      } else if (path.indexOf("/reports") == 0) {
        this.pageName = this.$t("reports");
        this.pageIcon = "reports.svg";
      } else if (path.indexOf("/privileges") == 0) {
        this.pageName = this.$t("privileges");
        this.pageIcon = "privileges.svg";
      } else if (path.indexOf("/branches-times") == 0) {
        this.pageName = this.$t("branchesTimes");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/add-branch-time") == 0) {
        this.pageName = this.$t("branchTimeAdd");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/edit-branch-time") == 0) {
        this.pageName = this.$t("branchTimeEdit");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/branches") == 0) {
        this.pageName = this.$t("theBranches");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/add-branch") == 0) {
        this.pageName = this.$t("branchAdd");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/edit-branch") == 0) {
        this.pageName = this.$t("branchEdit");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/method-paids") == 0) {
        this.pageName = this.$t("methodPaids");
        this.pageIcon = "method-paids.svg";
      } else if (path.indexOf("/add-method-paid") == 0) {
        this.pageName = this.$t("methodPaidAdd");
        this.pageIcon = "method-paids.svg";
      } else if (path.indexOf("/edit-method-paid") == 0) {
        this.pageName = this.$t("methodPaidEdit");
        this.pageIcon = "method-paids.svg";
      } else if (path.indexOf("/stores-times") == 0) {
        this.pageName = this.$t("storesTimes");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/add-store-time") == 0) {
        this.pageName = this.$t("storeTimeAdd");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/edit-store-time") == 0) {
        this.pageName = this.$t("storeTimeEdit");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/stores") == 0) {
        this.pageName = this.$t("theStores");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/add-store") == 0) {
        this.pageName = this.$t("storeAdd");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/edit-store") == 0) {
        this.pageName = this.$t("storeEdit");
        this.pageIcon = "stores.svg";
      } else if (path.indexOf("/scientific-degrees") == 0) {
        this.pageName = this.$t("scientificDegrees");
        this.pageIcon = "scientific-degrees.svg";
      } else if (path.indexOf("/add-scientific-degree") == 0) {
        this.pageName = this.$t("scientificDegreeAdd");
        this.pageIcon = "scientific-degrees.svg";
      } else if (path.indexOf("/edit-scientific-degree") == 0) {
        this.pageName = this.$t("scientificDegreeEdit");
        this.pageIcon = "scientific-degrees.svg";
      } else if (path.indexOf("/treasuries-transactions") == 0) {
        this.pageName = this.$t("treasuriesTransactions");
        this.pageIcon = "treasuries-transactions.svg";
      } else if (path.indexOf("/add-treasury-transaction") == 0) {
        this.pageName = this.$t("treasuryTransactionAdd");
        this.pageIcon = "treasuries-transactions.svg";
      } else if (path.indexOf("/treasuries") == 0) {
        this.pageName = this.$t("theTreasuries");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/add-treasury") == 0) {
        this.pageName = this.$t("treasuryAdd");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/edit-treasury") == 0) {
        this.pageName = this.$t("treasuryEdit");
        this.pageIcon = "treasuries.svg";
      } else if (path.indexOf("/clients") == 0) {
        this.pageName = this.$t("clients");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/add-client") == 0) {
        this.pageName = this.$t("clientAdd");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/edit-client") == 0) {
        this.pageName = this.$t("clientEdit");
        this.pageIcon = "clients.svg";
      } else if (path.indexOf("/settings-establishment") == 0) {
        this.pageName = this.$t("settings-establishment");
        this.pageIcon = "settings-establishment.svg";
      } else if (path.indexOf("/months-establishment-settings") == 0) {
        this.pageName = this.$t("establishmentMonthsSettings");
        this.pageIcon = "months-settings.svg";
      } else if (path.indexOf("/add-months-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentMonthsSettingAdd");
        this.pageIcon = "months-settings.svg";
      } else if (path.indexOf("/year-establishment-settings") == 0) {
        this.pageName = this.$t("establishmentYearSettings");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/add-year-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentYearSettingAdd");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/edit-year-establishment-setting") == 0) {
        this.pageName = this.$t("establishmentYearSettingEdit");
        this.pageIcon = "year-settings.svg";
      } else if (path.indexOf("/settings-other") == 0) {
        this.pageName = this.$t("settings-other");
        this.pageIcon = "settings-other.svg";
      } else if (path.indexOf("/sectors") == 0) {
        this.pageName = this.$t("sectors");
        this.pageIcon = "sectors.svg";
      } else if (path.indexOf("/jobs") == 0) {
        this.pageName = this.$t("jobs");
        this.pageIcon = "jobs.svg";
      } else if (path.indexOf("/qualifications") == 0) {
        this.pageName = this.$t("qualifications");
        this.pageIcon = "qualifications.svg";
      } else if (path.indexOf("/revenues-types") == 0) {
        this.pageName = this.$t("revenuesTypes");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/add-revenue-type") == 0) {
        this.pageName = this.$t("revenueTypeAdd");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/edit-revenue-type") == 0) {
        this.pageName = this.$t("revenueTypeEdit");
        this.pageIcon = "revenues-types.svg";
      } else if (path.indexOf("/expenses-types") == 0) {
        this.pageName = this.$t("expensesTypes");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/add-expense-type") == 0) {
        this.pageName = this.$t("expenseTypeAdd");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/edit-expense-type") == 0) {
        this.pageName = this.$t("expenseTypeEdit");
        this.pageIcon = "expenses-types.svg";
      } else if (path.indexOf("/news-categories") == 0) {
        this.pageName = this.$t("newsCategories");
        this.pageIcon = "category.svg";
      } else if (path.indexOf("/add-news-category") == 0) {
        this.pageName = this.$t("newsCategoryAdd");
        this.pageIcon = "category.svg";
      } else if (path.indexOf("/edit-news-category") == 0) {
        this.pageName = this.$t("newsCategoryEdit");
        this.pageIcon = "category.svg";
      } else if (path.indexOf("/news") == 0) {
        this.pageName = this.$t("news");
        this.pageIcon = "news.svg";
      } else if (path.indexOf("/add-news") == 0) {
        this.pageName = this.$t("newsAdd");
        this.pageIcon = "news.svg";
      } else if (path.indexOf("/edit-news") == 0) {
        this.pageName = this.$t("newsEdit");
        this.pageIcon = "news.svg";
      } else if (path.indexOf("/offers") == 0) {
        this.pageName = this.$t("offers");
        this.pageIcon = "offers.svg";
      } else if (path.indexOf("/add-offer") == 0) {
        this.pageName = this.$t("offerAdd");
        this.pageIcon = "offers.svg";
      } else if (path.indexOf("/edit-offer") == 0) {
        this.pageName = this.$t("offerEdit");
        this.pageIcon = "offers.svg";
      } else if (path.indexOf("/medicines-types") == 0) {
        this.pageName = this.$t("medicinesTyps");
        this.pageIcon = "medicines-types.svg";
      } else if (path.indexOf("/add-medicines-type") == 0) {
        this.pageName = this.$t("medicinesTypeAdd");
        this.pageIcon = "medicines-types.svg";
      } else if (path.indexOf("/edit-medicines-type") == 0) {
        this.pageName = this.$t("medicinesTypeEdit");
        this.pageIcon = "medicines-types.svg";
      } else if (path.indexOf("/medicines") == 0) {
        this.pageName = this.$t("medicines");
        this.pageIcon = "medicines.svg";
      } else if (path.indexOf("/add-medicine") == 0) {
        this.pageName = this.$t("medicineAdd");
        this.pageIcon = "medicines.svg";
      } else if (path.indexOf("/edit-medicine") == 0) {
        this.pageName = this.$t("medicineEdit");
        this.pageIcon = "medicines.svg";
      } else if (path.indexOf("/requirements") == 0) {
        this.pageName = this.$t("requirements");
        this.pageIcon = "requirements.svg";
      } else if (path.indexOf("/add-requirement") == 0) {
        this.pageName = this.$t("requirementAdd");
        this.pageIcon = "requirements.svg";
      } else if (path.indexOf("/edit-requirement") == 0) {
        this.pageName = this.$t("requirementEdit");
        this.pageIcon = "requirements.svg";
      } else if (path.indexOf("/blood-types") == 0) {
        this.pageName = this.$t("bloodTypes");
        this.pageIcon = "blood-types.svg";
      } else if (path.indexOf("/add-blood-type") == 0) {
        this.pageName = this.$t("bloodTypeAdd");
        this.pageIcon = "blood-types.svg";
      } else if (path.indexOf("/edit-blood-type") == 0) {
        this.pageName = this.$t("bloodTypeEdit");
        this.pageIcon = "blood-types.svg";
      } else if (path.indexOf("/chambers") == 0) {
        this.pageName = this.$t("chambers");
        this.pageIcon = "chambers.svg";
      } else if (path.indexOf("/add-chamber") == 0) {
        this.pageName = this.$t("chamberAdd");
        this.pageIcon = "chambers.svg";
      } else if (path.indexOf("/edit-chamber") == 0) {
        this.pageName = this.$t("chamberEdit");
        this.pageIcon = "chambers.svg";
      } else if (path.indexOf("/countries") == 0) {
        this.pageName = this.$t("setting.others.countries");
        this.pageIcon = "country.svg";
      } else if (path.indexOf("/add-country") == 0) {
        this.pageName = this.$t("countries.add");
        this.pageIcon = "country.svg";
      } else if (path.indexOf("/edit-country") == 0) {
        this.pageName = this.$t("countries.edit");
        this.pageIcon = "country.svg";
      } else if (path.indexOf("/governates") == 0) {
        this.pageName = this.$t("setting.others.governates");
        this.pageIcon = "governate.svg";
      } else if (path.indexOf("/add-governate") == 0) {
        this.pageName = this.$t("governates.add");
        this.pageIcon = "governate.svg";
      } else if (path.indexOf("/edit-governate") == 0) {
        this.pageName = this.$t("governates.edit");
        this.pageIcon = "governate.svg";
      } else if (path.indexOf("/cities") == 0) {
        this.pageName = this.$t("setting.others.cities");
        this.pageIcon = "city.svg";
      } else if (path.indexOf("/add-city") == 0) {
        this.pageName = this.$t("cities.add");
        this.pageIcon = "city.svg";
      } else if (path.indexOf("/edit-city") == 0) {
        this.pageName = this.$t("cities.edit");
        this.pageIcon = "city.svg";
      } else if (path.indexOf("/districts") == 0) {
        this.pageName = this.$t("setting.others.districts");
        this.pageIcon = "district.svg";
      } else if (path.indexOf("/add-district") == 0) {
        this.pageName = this.$t("districts.add");
        this.pageIcon = "district.svg";
      } else if (path.indexOf("/edit-district") == 0) {
        this.pageName = this.$t("districts.edit");
        this.pageIcon = "district.svg";
      } else if (path.indexOf("/insurance-companies") == 0) {
        this.pageName = this.$t("setting.others.insuranceCompanies");
        this.pageIcon = "insurance-companies.svg";
      } else if (path.indexOf("/add-insurance-company") == 0) {
        this.pageName = this.$t("insuranceCompanies.add");
        this.pageIcon = "insurance-companies.svg";
      } else if (path.indexOf("/edit-insurance-company") == 0) {
        this.pageName = this.$t("insuranceCompanies.edit");
        this.pageIcon = "insurance-companies.svg";
      } else if (path.indexOf("/offices-times") == 0) {
        this.pageName = this.$t("officesTimes");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/add-offices-time") == 0) {
        this.pageName = this.$t("officesTimeAdd");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/edit-offices-time") == 0) {
        this.pageName = this.$t("officesTimeEdit");
        this.pageIcon = "time.svg";
      } else if (path.indexOf("/offices") == 0) {
        this.pageName = this.$t("offices");
        this.pageIcon = "office.svg";
      } else if (path.indexOf("/add-office") == 0) {
        this.pageName = this.$t("officeAdd");
        this.pageIcon = "office.svg";
      } else if (path.indexOf("/edit-office") == 0) {
        this.pageName = this.$t("officeEdit");
        this.pageIcon = "office.svg";
      } else if (path.indexOf("/settings-branch") == 0) {
        this.pageName = this.$t("settingsBranch");
        this.pageIcon = "branches.svg";
      } else if (path.indexOf("/buildings") == 0) {
        this.pageName = this.$t("buildings");
        this.pageIcon = "buildings.svg";
      } else if (path.indexOf("/add-building") == 0) {
        this.pageName = this.$t("buildingAdd");
        this.pageIcon = "buildings.svg";
      } else if (path.indexOf("/edit-building") == 0) {
        this.pageName = this.$t("buildingEdit");
        this.pageIcon = "buildings.svg";
      } else if (path.indexOf("/floors") == 0) {
        this.pageName = this.$t("floors");
        this.pageIcon = "floors.svg";
      } else if (path.indexOf("/add-floor") == 0) {
        this.pageName = this.$t("floorAdd");
        this.pageIcon = "floors.svg";
      } else if (path.indexOf("/edit-floor") == 0) {
        this.pageName = this.$t("floorEdit");
        this.pageIcon = "floors.svg";
      } else if (path.indexOf("/general-specialties") == 0) {
        this.pageName = this.$t("generalSpecialties");
        this.pageIcon = "general-specialities.svg";
      } else if (path.indexOf("/add-general-specialty") == 0) {
        this.pageName = this.$t("generalSpecialtyAdd");
        this.pageIcon = "general-specialities.svg";
      } else if (path.indexOf("/edit-general-specialty") == 0) {
        this.pageName = this.$t("generalSpecialtyEdit");
        this.pageIcon = "general-specialities.svg";
      } else if (path.indexOf("/special-specialties") == 0) {
        this.pageName = this.$t("specialSpecialties");
        this.pageIcon = "special-spcialities.svg";
      } else if (path.indexOf("/add-special-specialty") == 0) {
        this.pageName = this.$t("specialSpecialtyAdd");
        this.pageIcon = "special-spcialities.svg";
      } else if (path.indexOf("/edit-special-specialty") == 0) {
        this.pageName = this.$t("specialSpecialtyEdit");
        this.pageIcon = "special-spcialities.svg";
      } else if (path.indexOf("/services-reservation-added-services") == 0) {
        this.pageName = this.$t("ServicesReservationAddedServices.modelName");
        this.pageIcon = "added-services.svg";
      } else if (path.indexOf("/add-services-reservation-added-service") == 0) {
        this.pageName = this.$t("ServicesReservationAddedServices.add");
        this.pageIcon = "added-services.svg";
      } else if (path.indexOf("/services-reservations") == 0) {
        this.pageName = this.$t("servicesReservations");
        this.pageIcon = "services-reservations.svg";
      } else if (path.indexOf("/add-services-reservation") == 0) {
        this.pageName = this.$t("servicesReservationAdd");
        this.pageIcon = "services-reservations.svg";
      } else if (path.indexOf("/services-payments") == 0) {
        this.pageName = this.$t("servicesPayments");
        this.pageIcon = "services-payments.svg";
      } else if (path.indexOf("/medication-plans") == 0) {
        this.pageName = this.$t("treatmentsReservation");
        this.pageIcon = "treatments-reservation.svg";
      } else if (path.indexOf("/add-medication-plan") == 0) {
        this.pageName = this.$t("treatmentsReservationAdd");
        this.pageIcon = "treatments-reservation.svg";
      } else if (path.indexOf("/edit-medication-plan") == 0) {
        this.pageName = this.$t("treatmentsReservationEdit");
        this.pageIcon = "treatments-reservation.svg";
      } else if (path.indexOf("/services-types") == 0) {
        this.pageName = this.$t("servicesTypes");
        this.pageIcon = "services-types.svg";
      } else if (path.indexOf("/add-services-type") == 0) {
        this.pageName = this.$t("servicesTypeAdd");
        this.pageIcon = "services-types.svg";
      } else if (path.indexOf("/edit-services-type") == 0) {
        this.pageName = this.$t("servicesTypeEdit");
        this.pageIcon = "services-types.svg";
      } else if (path.indexOf("/services") == 0) {
        this.pageName = this.$t("services");
        this.pageIcon = "services.svg";
      } else if (path.indexOf("/add-service") == 0) {
        this.pageName = this.$t("serviceAdd");
        this.pageIcon = "services.svg";
      } else if (path.indexOf("/edit-service") == 0) {
        this.pageName = this.$t("serviceEdit");
        this.pageIcon = "services.svg";
      } else if (path.indexOf("/work-shifts") == 0) {
        this.pageName = this.$t("workShifts");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/settings-salary") == 0) {
        this.pageName = this.$t("settings-salary");
        this.pageIcon = "settings-salary.svg";
      } else if (path.indexOf("/add-work-shift") == 0) {
        this.pageName = this.$t("workShiftAdd");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/edit-work-shift") == 0) {
        this.pageName = this.$t("workShiftEdit");
        this.pageIcon = "work-shifts.svg";
      } else if (path.indexOf("/work-plans") == 0) {
        this.pageName = this.$t("workPlans");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/add-work-shift") == 0) {
        this.pageName = this.$t("workPlanAdd");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/edit-work-shift") == 0) {
        this.pageName = this.$t("workPlanEdit");
        this.pageIcon = "work-plans.svg";
      } else if (path.indexOf("/work-plan-days") == 0) {
        this.pageName = this.$t("workPlanDays");
        this.pageIcon = "work-plan-days.svg";
      } else if (path.indexOf("/salary-effects") == 0) {
        this.pageName = this.$t("salaryEffects");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/add-salary-effect") == 0) {
        this.pageName = this.$t("salaryEffectAdd");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/edit-salary-effect") == 0) {
        this.pageName = this.$t("salaryEffectEdit");
        this.pageIcon = "salary-effects.svg";
      } else if (path.indexOf("/balances-plans") == 0) {
        this.pageName = this.$t("balancesPlans");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/add-balances-plan") == 0) {
        this.pageName = this.$t("balancesPlanAdd");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/balances-plan-details") == 0) {
        this.pageName = this.$t("balancesPlanDetails");
        this.pageIcon = "balances-plans.svg";
      } else if (path.indexOf("/add-allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetailsAdd");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/edit-allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetailsEdit");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/allowances-plan-details") == 0) {
        this.pageName = this.$t("allowancesPlanDetails");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/add-allowances-plan") == 0) {
        this.pageName = this.$t("allowancesPlanAdd");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/edit-allowances-plan") == 0) {
        this.pageName = this.$t("allowancesPlanEdit");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/allowances-plans") == 0) {
        this.pageName = this.$t("allowancesPlans");
        this.pageIcon = "allowances-plans.svg";
      } else if (path.indexOf("/add-month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetailsAdd");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/edit-month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetailsEdit");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/month-allowances-plan-details") == 0) {
        this.pageName = this.$t("monthAllowancesPlanDetails");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/add-month-allowances-plan") == 0) {
        this.pageName = this.$t("monthAllowancesPlanAdd");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/edit-month-allowances-plan") == 0) {
        this.pageName = this.$t("monthAllowancesPlanEdit");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/month-allowances-plans") == 0) {
        this.pageName = this.$t("monthAllowancesPlans");
        this.pageIcon = "month-allowances-plans.svg";
      } else if (path.indexOf("/allowances") == 0) {
        this.pageName = this.$t("allowances");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/add-allowance") == 0) {
        this.pageName = this.$t("allowanceAdd");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/edit-allowance") == 0) {
        this.pageName = this.$t("allowanceEdit");
        this.pageIcon = "allowances.svg";
      } else if (path.indexOf("/overtime-plans") == 0) {
        this.pageName = this.$t("overTimePlans");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/add-overtime-plan") == 0) {
        this.pageName = this.$t("overTimePlanAdd");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/overtime-plan-details") == 0) {
        this.pageName = this.$t("overTimePlanDetails");
        this.pageIcon = "overtime-plans.svg";
      } else if (path.indexOf("/absence-plans") == 0) {
        this.pageName = this.$t("absencePlans");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/add-absence-plan") == 0) {
        this.pageName = this.$t("absencePlanAdd");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/absence-plan-details") == 0) {
        this.pageName = this.$t("absencePlanDetails");
        this.pageIcon = "absence-plans.svg";
      } else if (path.indexOf("/main-columns") == 0) {
        this.pageName = this.$t("mainColumns");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/add-main-column") == 0) {
        this.pageName = this.$t("mainColumnAdd");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/main-column-details") == 0) {
        this.pageName = this.$t("mainColumnEdit");
        this.pageIcon = "main-columns.svg";
      } else if (path.indexOf("/insurances-plans") == 0) {
        this.pageName = this.$t("insurancesPlans");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetails");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/add-insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetailsAdd");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/edit-insurances-plan-details") == 0) {
        this.pageName = this.$t("insurancesPlanDetailsEdit");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/add-insurances-plan") == 0) {
        this.pageName = this.$t("insurancesPlanAdd");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/edit-insurances-plan") == 0) {
        this.pageName = this.$t("insurancesPlanEdit");
        this.pageIcon = "insurances-plans.svg";
      } else if (path.indexOf("/official-holidays-plans") == 0) {
        this.pageName = this.$t("officialHolidaysPlans");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetails");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/add-official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetailsAdd");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/edit-official-holidays-plan-details") == 0) {
        this.pageName = this.$t("officialHolidaysPlanDetailsEdit");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/add-official-holidays-plan") == 0) {
        this.pageName = this.$t("officialHolidaysPlanAdd");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/edit-official-holidays-plan") == 0) {
        this.pageName = this.$t("officialHolidaysPlanEdit");
        this.pageIcon = "official-holidays-plans.svg";
      } else if (path.indexOf("/official-holidays") == 0) {
        this.pageName = this.$t("officialHolidays");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/add-official-holiday") == 0) {
        this.pageName = this.$t("officialHolidayAdd");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/edit-official-holiday") == 0) {
        this.pageName = this.$t("officialHolidayEdit");
        this.pageIcon = "holiday.svg";
      } else if (path.indexOf("/surgeries") == 0) {
        this.pageName = this.$t("surgeries.modelName");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/add-surgery") == 0) {
        this.pageName = this.$t("surgeries.add");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/edit-surgery") == 0) {
        this.pageName = this.$t("surgeries.edit");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/meals-types") == 0) {
        this.pageName = this.$t("mealsTypes.modelName");
        this.pageIcon = "meals-types.svg";
      } else if (path.indexOf("/add-meals-type") == 0) {
        this.pageName = this.$t("mealsTypes.add");
        this.pageIcon = "meals-types.svg";
      } else if (path.indexOf("/edit-meals-type") == 0) {
        this.pageName = this.$t("mealsTypes.edit");
        this.pageIcon = "meals-types.svg";
      } else if (path.indexOf("/questions-types") == 0) {
        this.pageName = this.$t("questionsTypes.modelName");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/add-questions-type") == 0) {
        this.pageName = this.$t("questionsTypes.add");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/edit-questions-type") == 0) {
        this.pageName = this.$t("questionsTypes.edit");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/questions") == 0) {
        this.pageName = this.$t("questions.modelName");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/add-question") == 0) {
        this.pageName = this.$t("questions.add");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/edit-question") == 0) {
        this.pageName = this.$t("questions.edit");
        this.pageIcon = "questions.svg";
      } else if (path.indexOf("/meals") == 0) {
        this.pageName = this.$t("meals.modelName");
        this.pageIcon = "meal.svg";
      } else if (path.indexOf("/add-meal") == 0) {
        this.pageName = this.$t("meals.add");
        this.pageIcon = "meal.svg";
      } else if (path.indexOf("/edit-meal") == 0) {
        this.pageName = this.$t("meals.edit");
        this.pageIcon = "meal.svg";
      } else if (path.indexOf("/addictive-stuffs") == 0) {
        this.pageName = this.$t("addictiveStuffs.modelName");
        this.pageIcon = "habits.svg";
      } else if (path.indexOf("/add-addictive-stuff") == 0) {
        this.pageName = this.$t("addictiveStuffs.add");
        this.pageIcon = "habits.svg";
      } else if (path.indexOf("/edit-addictive-stuff") == 0) {
        this.pageName = this.$t("addictiveStuffs.edit");
        this.pageIcon = "habits.svg";
      } else if (path.indexOf("/doctor-notes") == 0) {
        this.pageName = this.$t("historicalPlans.doctorNotes.modelName");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/add-doctor-note") == 0) {
        this.pageName = this.$t("historicalPlans.doctorNotes.add");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/edit-doctor-note") == 0) {
        this.pageName = this.$t("historicalPlans.doctorNotes.edit");
        this.pageIcon = "medical-operations.svg";
      } else if (path.indexOf("/active-substances") == 0) {
        this.pageName = this.$t("activeSubstances.modelName");
        this.pageIcon = "active-substance.svg";
      } else if (path.indexOf("/add-active-substance") == 0) {
        this.pageName = this.$t("activeSubstances.add");
        this.pageIcon = "active-substance.svg";
      } else if (path.indexOf("/edit-active-substance") == 0) {
        this.pageName = this.$t("activeSubstances.edit");
        this.pageIcon = "active-substance.svg";
      } else if (path.indexOf("/calculate-payroll") == 0) {
        this.pageName = this.$t("calculatePayroll");
        this.pageIcon = "payroll.svg";
      } else if (path.indexOf("/revenues") == 0) {
        this.pageName = this.$t("revenues");
        this.pageIcon = "revenues.svg";
      } else if (path.indexOf("/add-revenue") == 0) {
        this.pageName = this.$t("revenueAdd");
        this.pageIcon = "revenues.svg";
      } else if (path.indexOf("/expenses") == 0) {
        this.pageName = this.$t("expenses");
        this.pageIcon = "expenses.svg";
      } else if (path.indexOf("/add-expense") == 0) {
        this.pageName = this.$t("expenseAdd");
        this.pageIcon = "expenses.svg";
      } else if (path.indexOf("/historical-plans") == 0) {
        this.pageName = this.$t("historicalPlans.modelName");
        this.pageIcon = "historicalPlans.svg";
      } else if (path.indexOf("/notifications") == 0) {
        this.pageName = this.$t("notifications.modelName");
        this.pageIcon = "notifications.svg";
      }
    },
    async getAllNotifications() {
      try {
        let response = await this.notificationData.getAllNotifications(
          this.language,
          this.userAuthorizeToken,
          1,
          5
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.notificationsData =
            response.data.notificationsPagination.notificationsData;
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.notificationsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.notificationsData = null;
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.notificationsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.notificationsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
    },
    async getCountNotReadNotifications() {
      this.isLoading = true;
      try {
        this.notificationsCount =
          await this.notificationData.getCountNotReadNotifications(
            this.language,
            this.userAuthorizeToken
          );
      } catch (e) {
        this.notificationsCount = 0;
      }
      this.isLoading = false;
    },
    async openAllNotifications() {
      try {
        let response = await this.notificationData.openAllNotifications(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.notificationsCount = 0;
          this.getAllNotifications();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.notificationsCount = 0;
        }
      } catch (e) {
        this.notificationsCount = 0;
      }
    },
    async readNotification(notification) {
      this.isLoading = true;
      try {
        this.notificationData.fillData(notification);
        await this.notificationData.readNotification(
          this.language,
          this.userAuthorizeToken
        );
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {
    ...mapGetters(["userData"]),
    employeeName: function () {
      if (this.userData) {
        return this.language == "ar"
          ? this.userData.employeePersonalData.employeeNameAr
          : this.userData.employeePersonalData.employeeNameEn;
      } else {
        return null;
      }
    },
    employeeImage: function () {
      return BASE_URL + this.userData.employeePersonalData.employeeImagePath;
    },
  },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
      this.getCountNotReadNotifications();
    },
  },
  async created() {
    this.getCurrentPage(this.$route.path);
    this.getCountNotReadNotifications();
  },
};
</script>

<style lang="scss"></style>
