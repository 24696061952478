<template>
  <div v-if="addictiveStuffClientsData" class="">
    <div class="table-container">
      <table class="my-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t("clientName") }}</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("responsibleForProvidingTheService") }}</th>
            <th>{{ $t("addictiveStuffs.recoveryState") }}</th>
            <th>{{ $t("addictiveStuffs.recoveryDate") }}</th>
            <th>{{ $t("addictiveStuffs.recoveryNotes") }}</th>
            <th>{{ $t("addictiveStuffs.injury.date") }}</th>
            <th>{{ $t("addictiveStuffs.injury.details") }}</th>
            <th>{{ $t("addictiveStuffs.injury.reason") }}</th>
            <th>{{ $t("addictiveStuffs.injury.syndrome") }}</th>
            <th>{{ $t("addictiveStuffs.injury.treatment") }}</th>
            <th>{{ $t("addictiveStuffs.injury.durationToNow") }}</th>
            <th>{{ $t("addictiveStuffs.injury.treatmentDuration") }}</th>
            <th>{{ $t("operationDateTime") }}</th>
            <th>{{ $t("edit") }}</th>
            <th>{{ $t("delete") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(addictiveStuffClient, index) in addictiveStuffClientsData"
            :key="index"
          >
            <td>{{ ++index }}</td>
            <td>
              {{ isDataExist(addictiveStuffClient.clientNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.addictiveStuffNameCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.addictiveStuffClientNotes) }}
            </td>
            <td>
              {{
                `${
                  addictiveStuffClient.employeeJobNameCurrentInReport != ""
                    ? addictiveStuffClient.employeeJobNameCurrentInReport
                    : $t("responsibleForProvidingTheService")
                }: ${isDataExist(
                  addictiveStuffClient.employeeNameCurrentInReport
                )}`
              }}
            </td>
            <td>
              {{
                isDataExist(
                  addictiveStuffClient.recoveryState
                    ? $t("addictiveStuffs.recoveried")
                    : $t("addictiveStuffs.Norecovery")
                )
              }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.recoveryDate) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.recoveryNotes) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.injuryDate) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.InjuryDetailsCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.InjuryReasonCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.InjurySyndromeCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.InjuryTreatmentCurrent) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.injuryDurationToNow) }}
            </td>
            <td>
              {{ isDataExist(addictiveStuffClient.treatmentDuration) }}
            </td>
            <td>
              {{
                formateDateTimeLang(
                  addictiveStuffClient.actionDate,
                  addictiveStuffClient.actionTime
                )
              }}
            </td>
            <td>
              <router-link
                v-if="
                  addictiveStuffClient.addictiveStuffTypeToken ==
                  addictiveStuffTypeToken.Habits
                "
                :to="{
                  name: 'AddictiveStuffClientHabitsEdit',
                  params: {
                    clientToken: addictiveStuffClient.clientToken,
                    addictiveStuffClientToken:
                      addictiveStuffClient.addictiveStuffClientToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
              <router-link
                v-else-if="
                  addictiveStuffClient.addictiveStuffTypeToken ==
                  addictiveStuffTypeToken.Diseases
                "
                :to="{
                  name: 'AddictiveStuffClientDiseasesEdit',
                  params: {
                    clientToken: addictiveStuffClient.clientToken,
                    addictiveStuffClientToken:
                      addictiveStuffClient.addictiveStuffClientToken,
                  },
                }"
                :title="$t('edit')"
              >
                <img src="@/assets/images/pencil.svg" class="icon-lg" />
              </router-link>
            </td>
            <td>
              <button
                v-b-modal.AddictiveStuffClientDelete
                class="btn p-0"
                :title="$t('delete')"
                @click="setAddictiveStuffClientData(addictiveStuffClient)"
              >
                <img src="@/assets/images/trash.svg" class="icon-lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import i18n from "@/i18n/index";
// import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import {
  setDataMultiLang,
  timeToLang,
  formateDateTimeLang,
  isDataExist,
} from "@/utils/functions";
import { ADDICTIVESTUFFS_TYPE_TOKENS } from "@/utils/constantLists";

export default {
  name: "AddictiveStuffClientsTable",
  components: {
    // DataLabelGroup,
  },
  data() {
    return {
      addictiveStuffTypeToken: ADDICTIVESTUFFS_TYPE_TOKENS,
      isAngleUp: false,
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["addictiveStuffClientsData", "addictiveStuffClientData"],
  methods: {
    setAddictiveStuffClientData(addictiveStuffClientData) {
      this.$emit("setAddictiveStuffClientData", addictiveStuffClientData);
    },
    toggleAngle() {
      this.isAngleUp = !this.isAngleUp;
    },
    downlaodTreatmentPlan(addictiveStuffClientData) {
      this.$emit("downlaodTreatmentPlan", addictiveStuffClientData);
    },
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn, dataUnd) {
      return setDataMultiLang(lang, dataAr, dataEn, dataUnd);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
