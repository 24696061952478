import axios from "axios";

import UsersPersonal from "@/models/privileges/UsersPersonal";
import UsersPrivilegeEmployees from "@/models/privileges/UsersPrivilegeEmployees";
import UsersPrivilegeUsers from "@/models/privileges/UsersPrivilegeUsers";
import UsersPrivilegeEstablishmentsSettings from "@/models/privileges/UsersPrivilegeEstablishmentsSettings";
import UsersPrivilegAdditionalSettings from "@/models/privileges/UsersPrivilegAdditionalSettings";
import UsersPrivilegeBranches from "@/models/privileges/UsersPrivilegeBranches";
import UsersPrivilegeClients from "@/models/privileges/UsersPrivilegeClients";
import UsersPrivilegeOfficesTimes from "@/models/privileges/UsersPrivilegeOfficesTimes";
import UsersPrivilegeServicesReservations from "@/models/privileges/UsersPrivilegeServicesReservations";
import UsersPrivilegeServicesReservationTreatmentPlan from "@/models/privileges/UsersPrivilegeServicesReservationTreatmentPlan";
import UsersPrivilegeNews from "@/models/privileges/UsersPrivilegeNews";
import UsersPrivilegeOffers from "@/models/privileges/UsersPrivilegeOffers";
import UsersPrivilegeTreasuries from "@/models/privileges/UsersPrivilegeTreasuries";
import UsersPrivilegeTreasuriesTransaction from "@/models/privileges/UsersPrivilegeTreasuriesTransaction";
import UsersPrivilegeExpenses from "@/models/privileges/UsersPrivilegeExpenses";
import UsersPrivilegeRevenus from "@/models/privileges/UsersPrivilegeRevenus";
import UsersPrivilegeServiceReservationMedia from "@/models/privileges/UsersPrivilegeServiceReservationMedia";
import UsersPrivilegeMedicationPlan from "@/models/privileges/UsersPrivilegeMedicationPlan";
import UsersPrivilegeMedicalRequirementsPlan from "@/models/privileges/UsersPrivilegeMedicalRequirementsPlan";
import UsersPrivilegeDiagnosisPlan from "@/models/privileges/UsersPrivilegeDiagnosisPlan";
import UsersPrivilegeServicesReservationAddedServices from "@/models/privileges/UsersPrivilegeServicesReservationAddedServices";
import UsersPrivilegeDoctorNotes from "@/models/privileges/UsersPrivilegeDoctorNotes";
import UsersPrivilegeCurePlans from "@/models/privileges/UsersPrivilegeCurePlans";
import UsersPrivilegeSurgriesHistory from "@/models/privileges/UsersPrivilegeSurgriesHistory";
import UsersPrivilegeQuestionPlan from "@/models/privileges/UsersPrivilegeQuestionPlan";
import UsersPrivilegeAddictiveStuffClient from "@/models/privileges/UsersPrivilegeAddictiveStuffClient";
import UsersPrivilegeMedicalAlerts from "@/models/privileges/UsersPrivilegeMedicalAlerts";

export default class UserPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.usersPersonalData = new UsersPersonal();
    this.usersPrivilegeEmployees = new UsersPrivilegeEmployees();
    this.usersPrivilegeUsers = new UsersPrivilegeUsers();
    this.usersPrivilegeEstablishmentsSettings =
      new UsersPrivilegeEstablishmentsSettings();
    this.usersPrivilegAdditionalSettings =
      new UsersPrivilegAdditionalSettings();
    this.usersPrivilegeBranches = new UsersPrivilegeBranches();
    this.usersPrivilegeClients = new UsersPrivilegeClients();
    this.usersPrivilegeOfficesTimes = new UsersPrivilegeOfficesTimes();
    this.usersPrivilegeServicesReservations =
      new UsersPrivilegeServicesReservations();
    this.usersPrivilegeServicesReservationTreatmentPlan =
      new UsersPrivilegeServicesReservationTreatmentPlan();
    this.usersPrivilegeNews = new UsersPrivilegeNews();
    this.usersPrivilegeOffers = new UsersPrivilegeOffers();
    this.usersPrivilegeTreasuries = new UsersPrivilegeTreasuries();
    this.usersPrivilegeTreasuriesTransaction =
      new UsersPrivilegeTreasuriesTransaction();
    this.usersPrivilegeExpenses = new UsersPrivilegeExpenses();
    this.usersPrivilegeRevenus = new UsersPrivilegeRevenus();
    this.usersPrivilegeServiceReservationMedia =
      new UsersPrivilegeServiceReservationMedia();
    this.usersPrivilegeMedicationPlan = new UsersPrivilegeMedicationPlan();
    this.usersPrivilegeMedicalRequirementsPlan =
      new UsersPrivilegeMedicalRequirementsPlan();
    this.usersPrivilegeDiagnosisPlan = new UsersPrivilegeDiagnosisPlan();
    this.usersPrivilegeServicesReservationAddedServices =
      new UsersPrivilegeServicesReservationAddedServices();
    this.usersPrivilegeDoctorNotes = new UsersPrivilegeDoctorNotes();
    this.usersPrivilegeCurePlans = new UsersPrivilegeCurePlans();
    this.usersPrivilegeSurgriesHistory = new UsersPrivilegeSurgriesHistory();
    this.usersPrivilegeQuestionPlan = new UsersPrivilegeQuestionPlan();
    this.usersPrivilegeAddictiveStuffClient =
      new UsersPrivilegeAddictiveStuffClient();
    this.usersPrivilegeMedicalAlerts = new UsersPrivilegeMedicalAlerts();
  }
  fillData(data) {
    this.usersPersonalData = data.usersPersonalData;
    this.usersPrivilegeEmployees = data.usersPrivilegeEmployees;
    this.usersPrivilegeUsers = data.usersPrivilegeUsers;
    this.usersPrivilegeEstablishmentsSettings =
      data.usersPrivilegeEstablishmentsSettings;
    this.usersPrivilegAdditionalSettings = data.usersPrivilegAdditionalSettings;
    this.usersPrivilegeBranches = data.usersPrivilegeBranches;
    this.usersPrivilegeClients = data.usersPrivilegeClients;
    this.usersPrivilegeOfficesTimes = data.usersPrivilegeOfficesTimes;
    this.usersPrivilegeServicesReservations =
      data.usersPrivilegeServicesReservations;
    this.usersPrivilegeServicesReservationTreatmentPlan =
      data.usersPrivilegeServicesReservationTreatmentPlan;
    this.usersPrivilegeNews = data.usersPrivilegeNews;
    this.usersPrivilegeOffers = data.usersPrivilegeOffers;
    this.usersPrivilegeTreasuries = data.usersPrivilegeTreasuries;
    this.usersPrivilegeTreasuriesTransaction =
      data.usersPrivilegeTreasuriesTransaction;
    this.usersPrivilegeExpenses = data.usersPrivilegeExpenses;
    this.usersPrivilegeRevenus = data.usersPrivilegeRevenus;
    this.usersPrivilegeServiceReservationMedia =
      data.usersPrivilegeServiceReservationMedia;
    this.usersPrivilegeMedicationPlan = data.usersPrivilegeMedicationPlan;
    this.usersPrivilegeMedicalRequirementsPlan =
      data.usersPrivilegeMedicalRequirementsPlan;
    this.usersPrivilegeDiagnosisPlan = data.usersPrivilegeDiagnosisPlan;
    this.usersPrivilegeServicesReservationAddedServices =
      data.usersPrivilegeServicesReservationAddedServices;
    this.usersPrivilegeDoctorNotes = data.usersPrivilegeDoctorNotes;
    this.usersPrivilegeCurePlans = data.usersPrivilegeCurePlans;
    this.usersPrivilegeSurgriesHistory = data.usersPrivilegeSurgriesHistory;
    this.usersPrivilegeQuestionPlan = data.usersPrivilegeQuestionPlan;
    this.usersPrivilegeAddictiveStuffClient =
      data.usersPrivilegeAddictiveStuffClient;
    this.usersPrivilegeMedicalAlerts = data.usersPrivilegeMedicalAlerts;
  }

  async getUserPrivilege(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/UsersPrivilege//GetUserPrivilege?language=${language}&userAuthorizeToken=${userAuthorizeToken}&employeeToken=${this.usersPersonalData.employeeToken}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async updateUserPrivilege() {
    var data = {
      usersPersonalData: this.usersPersonalData,
      usersPrivilegeEmployees: this.usersPrivilegeEmployees,
      usersPrivilegeUsers: this.usersPrivilegeUsers,
      usersPrivilegeEstablishmentsSettings:
        this.usersPrivilegeEstablishmentsSettings,
      usersPrivilegAdditionalSettings: this.usersPrivilegAdditionalSettings,
      usersPrivilegeBranches: this.usersPrivilegeBranches,
      usersPrivilegeClients: this.usersPrivilegeClients,
      usersPrivilegeOfficesTimes: this.usersPrivilegeOfficesTimes,
      usersPrivilegeServicesReservations:
        this.usersPrivilegeServicesReservations,
      usersPrivilegeServicesReservationTreatmentPlan:
        this.usersPrivilegeServicesReservationTreatmentPlan,
      usersPrivilegeNews: this.usersPrivilegeNews,
      usersPrivilegeOffers: this.usersPrivilegeOffers,
      usersPrivilegeTreasuries: this.usersPrivilegeTreasuries,
      usersPrivilegeTreasuriesTransaction:
        this.usersPrivilegeTreasuriesTransaction,
      usersPrivilegeExpenses: this.usersPrivilegeExpenses,
      usersPrivilegeRevenus: this.usersPrivilegeRevenus,
      usersPrivilegeServiceReservationMedia:
        this.usersPrivilegeServiceReservationMedia,
      usersPrivilegeMedicationPlan: this.usersPrivilegeMedicationPlan,
      usersPrivilegeMedicalRequirementsPlan:
        this.usersPrivilegeMedicalRequirementsPlan,
      usersPrivilegeDiagnosisPlan: this.usersPrivilegeDiagnosisPlan,
      usersPrivilegeServicesReservationAddedServices:
        this.usersPrivilegeServicesReservationAddedServices,
      usersPrivilegeDoctorNotes: this.usersPrivilegeDoctorNotes,
      usersPrivilegeCurePlans: this.usersPrivilegeCurePlans,
      usersPrivilegeSurgriesHistory: this.usersPrivilegeSurgriesHistory,
      usersPrivilegeQuestionPlan: this.usersPrivilegeQuestionPlan,
      usersPrivilegeAddictiveStuffClient:
        this.usersPrivilegeAddictiveStuffClient,
      usersPrivilegeMedicalAlerts: this.usersPrivilegeMedicalAlerts,
    };

    try {
      let response = await axios.post(
        `/UsersPrivilege//UpdateUserPrivilege`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
