<template>
  <div class="row">
    <CustomSelectBoxWithImg
      :className="'col-md-6'"
      :id="'clientToken'"
      :value="clientToken"
      :options="clientTokenOptions"
      v-on:changeValue="clientToken = $event"
      :title="$t('selectClient')"
      :imgName="'clients.svg'"
      :isSync="true"
      v-on:synchronization="getDialogOfClients()"
    />
  </div>
</template>

<script>
import CustomSelectBoxWithImg from "@/components/general/CustomSelectBoxWithImg.vue";
import Client from "@/models/clients/Client";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "Test",
  mixins: [createToastMixin],
  components: {
    CustomSelectBoxWithImg,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      clientData: new Client(),
      clientTokenOptions: [],
      clientToken: "",
    };
  },
  methods: {
    async getDialogOfClients() {
      this.isLoading = true;
      try {
        this.clientTokenOptions = await this.clientData.getDialogOfClients(
          this.language,
          this.userAuthorizeToken
        );
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },

  watch: {},
  async created() {
    this.getDialogOfClients();
  },
};
</script>
