<template>
  <ul>
    <!-- <li>
      <router-link to="/" :class="currentPage == 'home' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/homepage.svg" /></span>
        <span class="title">{{ $t("homePage") }}</span>
      </router-link>
    </li> -->
    <li v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeNews.newsView)">
      <router-link to="/news" :class="currentPage == 'news' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/news.svg" /></span>
        <span class="title">{{ $t("news") }}</span>
      </router-link>
    </li>
    <li
      v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeOffers.offersView)"
    >
      <router-link
        to="/offers"
        :class="currentPage == 'offers' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/offers.svg" /></span>
        <span class="title">{{ $t("offers") }}</span>
      </router-link>
    </li>

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeServicesReservations
            .servicesReservationView
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeServicesReservations
            .servicesReservationViewPay
        )
      "
    >
      <a
        v-b-toggle.reservationsAndPayments
        :class="currentPage == 'reservations-payments' ? 'active' : ''"
      >
        <span class="icon"
          ><img src="@/assets/images/services-reservations.svg"
        /></span>
        <span class="title">
          {{ $t("reservationsAndPayments") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="reservationsAndPayments">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeServicesReservations
                  .servicesReservationView
              )
            "
          >
            <a
              :class="currentSubPage == 'services-reservations' ? 'active' : ''"
              @click="goToServicesReservations()"
            >
              <span class="icon"
                ><img src="@/assets/images/services-reservations.svg"
              /></span>
              <span class="title">{{ $t("servicesReservations") }}</span>
            </a>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeServicesReservations
                  .servicesReservationViewPay
              )
            "
          >
            <a
              :class="currentSubPage == 'services-payments' ? 'active' : ''"
              @click="goToPayments()"
            >
              <span class="icon"
                ><img src="@/assets/images/services-payments.svg"
              /></span>
              <span class="title">{{ $t("servicesPayments") }}</span>
            </a>
          </li>
        </ul>
      </b-collapse>
    </li>
    <li>
      <a
        :class="currentPage == 'historical-plans' ? 'active' : ''"
        @click="goToHistoricalPlan()"
      >
        <span class="icon"
          ><img src="@/assets/images/historicalPlans.svg"
        /></span>
        <span class="title">{{ $t("historicalPlans.modelName") }}</span>
      </a>
    </li>

    <li
      v-if="
        checkPrivilege(usersPrivilegeData.usersPrivilegeClients.clientsView)
      "
    >
      <router-link
        to="/clients"
        :class="currentPage == 'clients' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/clients.svg" /></span>
        <span class="title">{{ $t("clients") }}</span>
      </router-link>
    </li>

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeTreasuries.treasuriesView
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeTreasuriesTransaction
            .treasuriesTransactionsView
        ) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeRevenus.revenuesView) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeExpenses.expensesView)
      "
    >
      <a v-b-toggle.finance :class="currentPage == 'finance' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/finance.svg" /></span>
        <span class="title">
          {{ $t("finance") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="finance">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeTreasuries.treasuriesView
              )
            "
          >
            <router-link
              to="/treasuries"
              :class="currentSubPage == 'treasuries' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries.svg"
              /></span>
              <span class="title">{{ $t("theTreasuries") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeTreasuriesTransaction
                  .treasuriesTransactionsView
              )
            "
          >
            <router-link
              to="/treasuries-transactions"
              :class="
                currentSubPage == 'treasuries-transactions' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/treasuries-transactions.svg"
              /></span>
              <span class="title">{{ $t("treasuriesTransactions") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeRevenus.revenuesView
              )
            "
          >
            <router-link
              to="/revenues"
              :class="currentSubPage == 'revenues' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/revenues.svg"
              /></span>
              <span class="title">{{ $t("revenues") }}</span>
            </router-link>
          </li>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeExpenses.expensesView
              )
            "
          >
            <router-link
              to="/expenses"
              :class="currentSubPage == 'expenses' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/expenses.svg"
              /></span>
              <span class="title">{{ $t("expenses") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeEmployees.employeesView
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
        )
      "
    >
      <a
        v-b-toggle.theEmployees
        :class="currentPage == 'theEmployees' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/employees.svg" /></span>
        <span class="title">
          {{ $t("theEmployees") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theEmployees">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeEmployees.employeesView
              )
            "
          >
            <router-link
              to="/employees"
              :class="currentSubPage == 'employees' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employees.svg"
              /></span>
              <span class="title">{{ $t("theEmployees") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeUsers.usersPrivilegeView
              )
            "
          >
            <router-link
              to="/privileges"
              :class="currentSubPage == 'privileges' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/privileges.svg"
              /></span>
              <span class="title">{{ $t("privileges") }}</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              to="/add-employee-salary-setting"
              :class="currentSubPage == 'employeeSalarySetting' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employee-salary-setting.svg"
              /></span>
              <span class="title">{{ $t("EmployeeSalarySettingAdd") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/add-employee-work-schedule"
              :class="currentSubPage == 'employeeWorkSchedule' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/work-schedule.svg"
              /></span>
              <span class="title">{{ $t("EmployeeWorkScheduleAdd") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/add-employee-official-holidays-plan"
              :class="
                currentSubPage == 'employeeOfficialHolidaysPlan' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/holiday.svg"
              /></span>
              <span class="title">{{
                $t("EmployeeOfficialHolidaysPlanAdd")
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/employees-input-sheets-attendance"
              :class="
                currentSubPage == 'employeesInputSheetsAttendance'
                  ? 'active'
                  : ''
              "
            >
              <span class="icon"><img src="@/assets/images/sheets.svg" /></span>
              <span class="title">{{
                $t("employeesInputSheetsAttendance")
              }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/employees-input-sheet-attendance-merges"
              :class="
                currentSubPage == 'employeesInputSheetAttendanceMerges'
                  ? 'active'
                  : ''
              "
            >
              <span class="icon"><img src="@/assets/images/merge.svg" /></span>
              <span class="title">{{
                $t("employeesInputSheetAttendanceMerges")
              }}</span>
            </router-link>
          </li> -->
        </ul>
      </b-collapse>
    </li>
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(usersPrivilegeData.usersPrivilegeBranches.branchesView)
      "
    >
      <a
        v-b-toggle.theBranches
        :class="currentPage == 'branches' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/branches.svg" /></span>
        <span class="title">
          {{ $t("theBranches") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theBranches">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBranches.branchesView
              )
            "
          >
            <router-link
              to="/branches"
              :class="currentPage == 'branches' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/branches.svg"
              /></span>
              <span class="title">{{ $t("theBranches") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegAdditionalSettings
                  .additionalSettingsView
              )
            "
          >
            <router-link
              to="/settings-branch"
              :class="currentSubPage == 'SettingsBranch' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/branches.svg"
              /></span>
              <span class="title">{{ $t("settingsBranch") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>

    <!-- <li class="sub-menu">
      <a
        v-b-toggle.attendance
        :class="currentPage == 'attendance' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/attendance.svg" /></span>
        <span class="title">
          {{ $t("attendanceAndDeparture") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="attendance">
        <ul>
          <li>
            <router-link
              to="/add-attendance"
              :class="currentSubPage == 'add-attendance' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-attendance.svg"
              /></span>
              <span class="title">{{ $t("checkIn") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/add-departure"
              :class="currentSubPage == 'add-departure' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/add-departure.svg"
              /></span>
              <span class="title">{{ $t("checkOut") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/attendance-history"
              :class="currentSubPage == 'attendance-history' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/attendanceHistory.svg"
              /></span>
              <span class="title">{{
                $t("attendanceAndDepartureHistory")
              }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li> -->
    <!-- <li>
      <router-link
        to="/points"
        :class="currentPage == 'points' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/points.svg" /></span>
        <span class="title">{{ $t("points") }}</span>
      </router-link>
    </li> -->
    <li
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeReports.paymentReportView
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeReports
            .treasuriesTransactionsReportView
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeReports
            .servicesReservationsReportView
        )
      "
    >
      <router-link
        to="/reports"
        :class="currentPage == 'reports' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reports.svg" /></span>
        <span class="title">{{ $t("reports") }}</span>
      </router-link>
    </li>

    <!-- <li>
      <router-link
        to="/stores"
        :class="currentPage == 'stores' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/stores.svg" /></span>
        <span class="title">{{ $t("theStores") }}</span>
      </router-link>
    </li> -->

    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegAdditionalSettings
            .additionalSettingsView
        )
      "
    >
      <a v-b-toggle.settings :class="currentPage == 'settings' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>
        <span class="title">
          {{ $t("settings") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="settings">
        <ul>
          <!-- <li>
            <router-link
              to="/settings-establishment"
              :class="
                currentSubPage == 'settings-establishment' ? 'active' : ''
              "
            >
              <span class="icon"
                ><img src="@/assets/images/settings-establishment.svg"
              /></span>
              <span class="title">{{ $t("settings-establishment") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              to="/settings-salary"
              :class="currentSubPage == 'settings-salary' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-salary.svg"
              /></span>
              <span class="title">{{ $t("settings-salary") }}</span>
            </router-link>
          </li> -->

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegAdditionalSettings
                  .additionalSettingsView
              )
            "
          >
            <router-link
              to="/settings-other"
              :class="currentSubPage == 'settings-other' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-other.svg"
              /></span>
              <span class="title">{{ $t("settings-other") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import { checkPrivilege } from "@/utils/functions";
import UserPrivilege from "@/models/privileges/UserPrivilege";

export default {
  name: "Sidebar",
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      usersPrivilegeData:
        this.$store.getters.userData.usersPrivilegeData || new UserPrivilege(),
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    getCurrentPage(path) {
      if (path == "/") this.currentPage = "home";
      else if (
        path.indexOf("/employee-slices") == 0 ||
        path.indexOf("/add-employee-slice") == 0
      ) {
        this.currentPage = "employeeSlices";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/add-employee-salary-setting") == 0 ||
        path.indexOf("/edit-employee-salary-setting") == 0
      ) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employeeSalarySetting";
      } else if (path.indexOf("/add-employee-work-schedule") == 0) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employeeWorkSchedule";
      } else if (path.indexOf("/add-employee-official-holidays-plan") == 0) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employeeOfficialHolidaysPlan";
      } else if (
        path.indexOf("/employees-input-sheet-attendance-merges") == 0 ||
        path.indexOf("/add-employees-input-sheet-attendance-merge") == 0
      ) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employeesInputSheetAttendanceMerges";
      } else if (
        path.indexOf("/employees-input-sheets-attendance") == 0 ||
        path.indexOf("/add-employees-input-sheet-attendance") == 0
      ) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employeesInputSheetsAttendance";
      } else if (
        path.indexOf("/employee-salary-setting") == 0 ||
        path.indexOf("/employee-work-schedule") == 0 ||
        path.indexOf("/employee-official-holidays-plans") == 0 ||
        path.indexOf("/employees") == 0 ||
        path.indexOf("/add-employee") == 0 ||
        path.indexOf("/edit-employee") == 0
      ) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "employees";
      } else if (
        path.indexOf("/attendance-employee") == 0 ||
        path.indexOf("/add-attendance-employee") == 0 ||
        path.indexOf("/edit-attendance-employee") == 0
      ) {
        this.currentPage = "employeeAttendance";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/movement-employee") == 0 ||
        path.indexOf("/add-movement-employee") == 0 ||
        path.indexOf("/edit-movement-employee") == 0
      ) {
        this.currentPage = "employeeMovement";
        this.currentSubPage = "";
      } else if (path.indexOf("/employee-movement-media") == 0) {
        this.currentPage = "employeeMovementMedia";
        this.currentSubPage = "";
      } else if (path.indexOf("/attendance-history") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "attendance-history";
      } else if (path.indexOf("/add-attendance") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "add-attendance";
      } else if (path.indexOf("/add-departure") == 0) {
        this.currentPage = "attendance";
        this.currentSubPage = "add-departure";
      } else if (path.indexOf("/points") == 0) {
        this.currentPage = "points";
        this.currentSubPage = "";
      } else if (path.indexOf("/employee-points") == 0) {
        this.currentPage = "employeePoints";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/reports") == 0 ||
        path.indexOf("/calculate-payroll") == 0
      ) {
        this.currentPage = "reports";
        this.currentSubPage = "";
      } else if (path.indexOf("/privileges") == 0) {
        this.currentPage = "theEmployees";
        this.currentSubPage = "privileges";
      } else if (
        path.indexOf("/branches-times") == 0 ||
        path.indexOf("/add-branch-time") == 0 ||
        path.indexOf("/edit-branch-time") == 0 ||
        path.indexOf("/branches") == 0 ||
        path.indexOf("/add-branch") == 0 ||
        path.indexOf("/edit-branch") == 0
      ) {
        this.currentPage = "branches";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/stores-times") == 0 ||
        path.indexOf("/add-store-time") == 0 ||
        path.indexOf("/edit-store-time") == 0 ||
        path.indexOf("/stores") == 0 ||
        path.indexOf("/add-store") == 0 ||
        path.indexOf("/edit-store") == 0
      ) {
        this.currentPage = "stores";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/treasuries-transactions") == 0 ||
        path.indexOf("/add-treasury-transaction") == 0
      ) {
        this.currentPage = "finance";
        this.currentSubPage = "treasuries-transactions";
      } else if (
        path.indexOf("/treasuries") == 0 ||
        path.indexOf("/add-treasury") == 0 ||
        path.indexOf("/edit-treasury") == 0
      ) {
        this.currentPage = "finance";
        this.currentSubPage = "treasuries";
      } else if (
        path.indexOf("/clients") == 0 ||
        path.indexOf("/add-client") == 0 ||
        path.indexOf("/edit-client") == 0
      ) {
        this.currentPage = "clients";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/services-reservation-added-services") == 0 ||
        path.indexOf("/add-services-reservation-added-service") == 0
      ) {
        this.currentPage = "services-reservation-added-services";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/services-reservations") == 0 ||
        path.indexOf("/add-services-reservation") == 0 ||
        path.indexOf("/treatments-reservation") == 0 ||
        path.indexOf("/add-treatments-reservation") == 0
      ) {
        this.currentPage = "reservations-payments";
        this.currentSubPage = "services-reservations";
      } else if (path.indexOf("/services-payments") == 0) {
        this.currentPage = "reservations-payments";
        this.currentSubPage = "services-payments";
      } else if (
        path.indexOf("/news-categories") == 0 ||
        path.indexOf("/add-news-category") == 0 ||
        path.indexOf("/edit-news-category") == 0
      ) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-other";
      } else if (
        path.indexOf("/news") == 0 ||
        path.indexOf("/add-news") == 0 ||
        path.indexOf("/edit-news") == 0
      ) {
        this.currentPage = "news";
        this.currentSubPage = "";
      } else if (
        path.indexOf("/offers") == 0 ||
        path.indexOf("/add-offer") == 0 ||
        path.indexOf("/edit-offer") == 0
      ) {
        this.currentPage = "offers";
        this.currentSubPage = "";
      } else if (path.indexOf("/settings-branch") == 0) {
        this.currentPage = "settings";
        this.currentSubPage = "SettingsBranch";
      } else if (
        path.indexOf("/settings-establishment") == 0 ||
        path.indexOf("/months-establishment-settings") == 0 ||
        path.indexOf("/add-months-establishment-setting") == 0 ||
        path.indexOf("/year-establishment-settings") == 0 ||
        path.indexOf("/add-year-establishment-setting") == 0 ||
        path.indexOf("/edit-year-establishment-setting") == 0
      ) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-establishment";
      } else if (
        path.indexOf("/settings-other") == 0 ||
        path.indexOf("/sectors") == 0 ||
        path.indexOf("/jobs") == 0 ||
        path.indexOf("/qualifications") == 0 ||
        path.indexOf("/revenues-types") == 0 ||
        path.indexOf("/add-revenue-type") == 0 ||
        path.indexOf("/edit-revenue-type") == 0 ||
        path.indexOf("/expenses-types") == 0 ||
        path.indexOf("/add-expense-type") == 0 ||
        path.indexOf("/edit-expense-type") == 0 ||
        path.indexOf("/general-specialties") == 0 ||
        path.indexOf("/add-general-specialty") == 0 ||
        path.indexOf("/edit-general-specialty") == 0 ||
        path.indexOf("/special-specialties") == 0 ||
        path.indexOf("/add-special-specialty") == 0 ||
        path.indexOf("/edit-special-specialty") == 0 ||
        path.indexOf("/services-types") == 0 ||
        path.indexOf("/add-services-type") == 0 ||
        path.indexOf("/edit-services-type") == 0 ||
        path.indexOf("/services") == 0 ||
        path.indexOf("/add-service") == 0 ||
        path.indexOf("/edit-service") == 0 ||
        path.indexOf("/countries") == 0 ||
        path.indexOf("/add-country") == 0 ||
        path.indexOf("/edit-country") == 0 ||
        path.indexOf("/governates") == 0 ||
        path.indexOf("/add-governate") == 0 ||
        path.indexOf("/edit-governate") == 0 ||
        path.indexOf("/cities") == 0 ||
        path.indexOf("/add-city") == 0 ||
        path.indexOf("/edit-city") == 0 ||
        path.indexOf("/districts") == 0 ||
        path.indexOf("/add-district") == 0 ||
        path.indexOf("/edit-district") == 0 ||
        path.indexOf("/insuranceCompanies") == 0 ||
        path.indexOf("/add-insurance-company") == 0 ||
        path.indexOf("/edit-insurance-company") == 0
      ) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-other";
      } else if (
        path.indexOf("/settings-salary") == 0 ||
        path.indexOf("/work-shifts") == 0 ||
        path.indexOf("/add-work-shift") == 0 ||
        path.indexOf("/edit-work-shift") == 0 ||
        path.indexOf("/work-plans") == 0 ||
        path.indexOf("/add-work-plan") == 0 ||
        path.indexOf("/edit-work-plan") == 0 ||
        path.indexOf("/work-plan-days") == 0 ||
        path.indexOf("/add-salary-effect") == 0 ||
        path.indexOf("/edit-salary-effect") == 0 ||
        path.indexOf("/salary-effects") == 0 ||
        path.indexOf("/add-balances-plan") == 0 ||
        path.indexOf("/balances-plan-details") == 0 ||
        path.indexOf("/balances-plans") == 0 ||
        path.indexOf("/add-allowances-plan-details") == 0 ||
        path.indexOf("/edit-allowances-plan-details") == 0 ||
        path.indexOf("/allowances-plan-details") == 0 ||
        path.indexOf("/add-allowances-plan") == 0 ||
        path.indexOf("/edit-allowances-plan") == 0 ||
        path.indexOf("/allowances-plans") == 0 ||
        path.indexOf("/add-month-allowances-plan-details") == 0 ||
        path.indexOf("/edit-month-allowances-plan-details") == 0 ||
        path.indexOf("/month-allowances-plan-details") == 0 ||
        path.indexOf("/add-month-allowances-plan") == 0 ||
        path.indexOf("/edit-month-allowances-plan") == 0 ||
        path.indexOf("/month-allowances-plans") == 0 ||
        path.indexOf("/add-allowance") == 0 ||
        path.indexOf("/edit-allowance") == 0 ||
        path.indexOf("/allowances") == 0 ||
        path.indexOf("/add-overtime-plan") == 0 ||
        path.indexOf("/overtime-plan-details") == 0 ||
        path.indexOf("/overtime-plans") == 0 ||
        path.indexOf("/add-absences-plan") == 0 ||
        path.indexOf("/absences-plan-details") == 0 ||
        path.indexOf("/absences-plans") == 0 ||
        path.indexOf("/add-main-column") == 0 ||
        path.indexOf("/edit-main-column") == 0 ||
        path.indexOf("/main-columns") == 0 ||
        path.indexOf("/add-insurances-plan-details") == 0 ||
        path.indexOf("/edit-insurances-plan-details") == 0 ||
        path.indexOf("/insurances-plan-details") == 0 ||
        path.indexOf("/add-insurances-plan") == 0 ||
        path.indexOf("/edit-insurances-plan") == 0 ||
        path.indexOf("/insurances-plans") == 0 ||
        path.indexOf("/add-official-holidays-plan-details") == 0 ||
        path.indexOf("/edit-official-holidays-plan-details") == 0 ||
        path.indexOf("/official-holidays-plan-details") == 0 ||
        path.indexOf("/add-official-holidays-plan") == 0 ||
        path.indexOf("/edit-official-holidays-plan") == 0 ||
        path.indexOf("/official-holidays-plans") == 0 ||
        path.indexOf("/add-official-holiday") == 0 ||
        path.indexOf("/edit-official-holiday") == 0 ||
        path.indexOf("/official-holidays") == 0
      ) {
        this.currentPage = "settings";
        this.currentSubPage = "settings-salary";
      } else if (
        path.indexOf("/revenues") == 0 ||
        path.indexOf("/add-revenue") == 0 ||
        path.indexOf("/edit-revenue") == 0
      ) {
        this.currentPage = "finance";
        this.currentSubPage = "revenues";
      } else if (
        path.indexOf("/expenses") == 0 ||
        path.indexOf("/add-expense") == 0 ||
        path.indexOf("/edit-expense") == 0
      ) {
        this.currentPage = "finance";
        this.currentSubPage = "expenses";
      } else if (path.indexOf("/historical-plans") == 0) {
        this.currentPage = "historical-plans";
        this.currentSubPage = "";
      } else if (path.indexOf("/notifications") == 0) {
        this.currentPage = "notifications";
        this.currentSubPage = "";
      }
    },
    checkPrivilege(privilegeStatus) {
      return checkPrivilege(privilegeStatus);
    },
    goToServicesReservations() {
      this.$store.dispatch("updateReservationsClientToken", "");
      this.$router.push({ name: "ServicesReservations" }).catch(() => {});
    },
    goToPayments() {
      this.$store.dispatch("updatePaymentsClientToken", "");
      this.$store.dispatch("updatePaymentsReservationToken", "");
      this.$router.push({ name: "ServicesPayments" }).catch(() => {});
    },
    goToHistoricalPlan() {
      this.$store.dispatch("updateHistoricalPlansClientToken", "");
      this.$store.dispatch("updateHistoricalPlansReservationToken", "");
      this.$router.push({ name: "DiagnosisPlans" }).catch(() => {});
    },
  },
  watch: {
    $route(to) {
      this.getCurrentPage(to.path);
    },
  },
  async created() {
    this.getCurrentPage(this.$route.path);
  },
};
</script>

<style lang="scss"></style>
